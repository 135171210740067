import { faCaretLeft, faInbox, faStar, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { connectionSearch } from "../../actions/connections";
import { AppDispatch, RootState } from "../../app/store";
import { ConnectionStatus } from "../../constants";
import { ConnectionWithSeekerExperience } from "../../data/connection";
import { ConnectionFilters } from "../../data/miscellaneous";
import { parseConnections, ParsedConnection } from "../../utils/connections";
import { parseConversations, ParsedConversation } from "../../utils/conversations";
import { ConnectionListItemTypeBase } from "../connections/ConnectionList";
import ConnectionsViewer from "../connections/ConnectionsViewer";
import {
  ConnectionFilters_All,
  ConnectionFilters_Favorites,
  ConnectionFilters_Viewed,
  connectionFiltersForCategory,
} from "../connections/filters";
import JobDropdownToggle from "../jobs/JobDropdownToggle";
import { SearchParams } from "./routes";

const INITIAL_MAX_ITEMS = process.env.NODE_ENV === "production" ? 100 : 10; // use smaller values in development to make paging testing easier;
const FILTER_BUTTONS = [
  {
    label: "All",
    icon: faInbox,
    category: ConnectionFilters_All.category,
    filters: ConnectionFilters_All.search,
  },
  {
    label: "Viewed",
    icon: faUserCheck,
    category: ConnectionFilters_Viewed.category,
    filters: ConnectionFilters_Viewed.search,
  },
  {
    label: "Favorites",
    icon: faStar,
    category: ConnectionFilters_Favorites.category,
    filters: ConnectionFilters_Favorites.search,
  },
];

type LocationConnectionsProps = LocationConnectionsConnectedProps & {
  locationId: number;
  category?: string;
  jobId?: number;
  selectedConnectionId?: number;
};

const LocationConnections = ({
  managedLocations,
  connections,
  totalConnectionCount,
  searchInProgress,
  searchFilters,
  conversations,
  connectionSearch,
  locationId,
  category,
  jobId,
  selectedConnectionId,
}: LocationConnectionsProps) => {
  const connectionsViewerKey = useRef(0);
  const refreshSearchResults = useCallback(
    (newFilters: ConnectionFilters) => {
      connectionSearch(newFilters).catch(() => {});
    },
    [connectionSearch]
  );

  const location = managedLocations!.find((l) => l.id === locationId)!;

  const jobSelectorSearchTerm = useCallback(
    (jobId: number | null) => {
      let searchTerm = `?category=${category}`;
      if (jobId) {
        searchTerm += `&job_id=${jobId}`;
      }
      return searchTerm;
    },
    [category]
  );

  const jobCategorySearchTerm = useCallback(
    (category: string) => {
      let searchTerm = `?${SearchParams.Category}=${category}`;
      if (jobId) {
        searchTerm += `&job_id=${jobId}`;
      }
      return searchTerm;
    },
    [jobId]
  );

  useEffect(() => {
    const filters = { ...(category ? connectionFiltersForCategory(category)!.search : ConnectionFilters_All.search) };
    if (jobId) {
      filters["job_ids"] = [jobId];
    } else {
      filters["location_ids"] = [location.id];
    }
    filters["max_items"] = INITIAL_MAX_ITEMS;
    connectionsViewerKey.current = connectionsViewerKey.current + 1;
    refreshSearchResults(filters);
  }, [jobId, location, category, refreshSearchResults]);

  const moreItemsClickHandler = useCallback(() => {
    const updatedFilters = { ...searchFilters };
    updatedFilters.max_items = updatedFilters.max_items ?? 0 + INITIAL_MAX_ITEMS;
    refreshSearchResults(updatedFilters);
  }, [searchFilters, refreshSearchResults]);

  const linkToSearchParams = useMemo(() => {
    const _linkToSearchParams = new URLSearchParams();
    if (category) {
      _linkToSearchParams.append(SearchParams.Category, category);
    }
    if (jobId) {
      _linkToSearchParams.append(SearchParams.JobId, jobId.toString());
    }
    return _linkToSearchParams;
  }, [category, jobId]);

  const itemLinkTo = useCallback(
    (item: ParsedConnection<ConnectionWithSeekerExperience, ParsedConversation>) => {
      linkToSearchParams.set(SearchParams.SelectedConnectionId, item.id.toString());
      return { search: linkToSearchParams.toString() };
    },
    [linkToSearchParams]
  );

  const parsedConnections = useMemo(() => {
    if (connections) {
      const parsedConnections = parseConnections<ConnectionWithSeekerExperience, ParsedConversation>(connections, conversations);
      const statuses = searchFilters.status ?? null;
      return parsedConnections.filter((c) => {
        if (statuses !== null && statuses.includes(ConnectionStatus.Declined)) {
          return true;
        } else {
          return c.status !== ConnectionStatus.Declined;
        }
      });
    } else {
      return [];
    }
  }, [connections, conversations, searchFilters]);

  const filteredItemCount = connections ? connections.length - parsedConnections!.length : 0;

  const selectedJob = jobId ? location.jobs.find((j) => j.id === jobId) : null;

  return (
    <div className="h-100 d-flex flex-column gap-3">
      <div style={{ flex: "0 0 auto" }}>
        <Nav defaultActiveKey={0} className="location-connections-filters">
          <div className="border-end me-2 px-2 align-self-center">
            <Link to={`/locations/${location.id}`}>
              <FontAwesomeIcon icon={faCaretLeft} className="fs-4"></FontAwesomeIcon>
            </Link>
          </div>
          {FILTER_BUTTONS.map((button, index) => (
            <LinkContainer key={`button-${index}`} to={{ search: jobCategorySearchTerm(button.category) }}>
              <Nav.Link className="p-2" active={category === button.category}>
                <FontAwesomeIcon icon={button.icon} className="me-2" />
                {button.label}
              </Nav.Link>
            </LinkContainer>
          ))}
          <div className="d-flex flex-row align-items-center border-start ps-3">
            <span className="me-2">Position</span>
            <Dropdown>
              <Dropdown.Toggle as={JobDropdownToggle}>{selectedJob?.title ?? "All Positions"}</Dropdown.Toggle>
              <Dropdown.Menu>
                <LinkContainer to={{ search: jobSelectorSearchTerm(null) }}>
                  <Dropdown.Item>All Positions</Dropdown.Item>
                </LinkContainer>
                {location.jobs.map((job) => (
                  <LinkContainer key={`job-${job.id}`} to={{ search: jobSelectorSearchTerm(job.id) }}>
                    <Dropdown.Item>{job.title}</Dropdown.Item>
                  </LinkContainer>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </div>
      <ConnectionsViewer<ConnectionListItemTypeBase>
        key={`cn-${connectionsViewerKey.current}`}
        connections={parsedConnections}
        totalConnectionCount={totalConnectionCount - filteredItemCount}
        selectedConnectionId={selectedConnectionId}
        searchInProgress={searchInProgress}
        onMoreItemsClicked={moreItemsClickHandler}
        itemLink={itemLinkTo}
        style={{ flex: "1 1", minHeight: "0px" }}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    managedLocations: state.locations.locations ?? null,
    connections: state.connections.searchResults ?? null,
    totalConnectionCount: state.connections.searchTotalCount ?? 0,
    searchFilters: state.connections.searchFilters ?? {},
    searchInProgress: state.connections.searchInProgress ?? false,
    conversations: parseConversations(state.conversations.conversations ?? []),
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    connectionSearch: (filters: ConnectionFilters) => dispatch(connectionSearch(filters)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type LocationConnectionsConnectedProps = ConnectedProps<typeof connector>;

export default connector(LocationConnections);
