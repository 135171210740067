import React, { useCallback, useMemo, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { addConversationMessage, startConversation } from "../../actions/conversations";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import { ChatSnippet } from "../../data/miscellaneous";
import { ParsedConversation } from "../../utils/conversations";
import { Conversation } from "../../data/conversation";

type ConversationToolbarProps = ConversationToolbarConnectedProps & {
  conversation: ParsedConversation;
  connectionId?: number;
  enabled?: boolean;
  onConversationUpdated?: (conversation: Conversation) => void;
};

function ConversationToolbar({
  conversation,
  connectionId,
  snippets,
  enabled,
  onConversationUpdated,
  addConversationMessage,
  startConversation,
}: ConversationToolbarProps) {
  const [sendingMessage, setSendingMessage] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const sendPressed = useCallback(() => {
    const trimmedMessage = newMessage.trim();
    if (trimmedMessage.length === 0) {
      return;
    }
    setSendingMessage(true);
    const sendPromise = conversation
      ? addConversationMessage(conversation.id, trimmedMessage)
      : startConversation(connectionId!, trimmedMessage);
    sendPromise
      .then((conversation) => {
        if (onConversationUpdated) {
          onConversationUpdated(conversation);
        }
      })
      .catch((error) => {
        console.error(error);
        AppToast.show({ text: "An unexepected error occurred", type: "error" });
      })
      .finally(() => {
        setSendingMessage(false);
        setNewMessage("");
      });
  }, [
    conversation,
    newMessage,
    setNewMessage,
    addConversationMessage,
    startConversation,
    connectionId,
    onConversationUpdated,
  ]);

  const addSnippet = useCallback(
    (snippet: ChatSnippet) => {
      if (newMessage == null || newMessage.length === 0) {
        setNewMessage(snippet.value);
      } else {
        setNewMessage(`${newMessage}${newMessage.length === 0 || newMessage.endsWith(" ") ? "" : " "}${snippet.value}`);
      }
    },
    [newMessage, setNewMessage]
  );

  const preparedSnippets = useMemo(() => {
    const locationId = conversation.employer.location_id;
    const seekerId = conversation.seeker.id;
    return snippets?.map((snippet) => ({
      name: snippet.name,
      value: snippet.value
        .replace(/\$\{location.id\}/i, locationId.toString())
        .replace(/\$\{seeker.id\}/i, seekerId.toString()),
    }));
  }, [snippets, conversation]);

  const sendEnabled = enabled && !sendingMessage;

  return (
    <React.Fragment>
      {sendEnabled && preparedSnippets && preparedSnippets.length > 0 && (
        <div className="chat-snippets-toolbar mb-2">
          {preparedSnippets.map((snippet, index) => (
            <button key={`snippet-${index}`} onClick={() => addSnippet(snippet)}>
              {snippet.name}
            </button>
          ))}
        </div>
      )}
      <div className="d-flex flex-row gap-2 align-items-start">
        <Form.Control
          type="text"
          as="textarea"
          placeholder="Enter message"
          aria-label="Chat message"
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
          disabled={!sendEnabled}
        />
        <Button variant="primary" disabled={!sendEnabled} onClick={sendPressed}>
          {sendingMessage ? "Sending..." : "Send"}
        </Button>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    snippets: (state.initialization.appSettings?.chat_snippets as ChatSnippet[]) || [],
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    startConversation: (connectionId: number, message: string) => dispatch(startConversation(connectionId, message)),
    addConversationMessage: (conversationId: number, message: string) =>
      dispatch(addConversationMessage(conversationId, message)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConversationToolbarConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConversationToolbar);
