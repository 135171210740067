import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface InitializationState {
  isDataLoaded: boolean;
  initialAppPath?: string;
  appSettings: { [key: string]: any } | null;
}

const initialState: InitializationState = {
  isDataLoaded: false,
  appSettings: null,
};
export const initializationSlice = createSlice({
  name: "initialization",
  initialState,
  reducers: {
    setDataLoaded: (state, action: PayloadAction<boolean>) => {
      state.isDataLoaded = action.payload;
    },
    setAppSettings: (state, action: PayloadAction<{ [key: string]: any } | null>) => {
      state.appSettings = action.payload;
    },
    setInitialAppPath: (state, action: PayloadAction<string | undefined>) => {
      state.initialAppPath = action.payload;
    },
  },
});

export const { setDataLoaded, setAppSettings, setInitialAppPath } = initializationSlice.actions;
export default initializationSlice.reducer;
