import { faCircleCheck, faClock, faStar as faRegularStar, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import {
  faDownLeftAndUpRightToCenter,
  faList,
  faLocationDot,
  faStar as faSolidStar,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";
import parseISO from "date-fns/parseISO";
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  acceptConnection,
  declineConnection,
  getConnectionById,
  updateConnectionById,
} from "../../actions/connections";
import { getLocationById } from "../../actions/locations";
import { AppDispatch, RootState } from "../../app/store";
import AppToast from "../../components/AppToast";
import Avatar from "../../components/Avatar";
import IconButton from "../../components/IconButton";
import LoadingOverlay from "../../components/LoadingOverlay";
import { ConnectionStatus, connectionStatusLabel } from "../../constants";
import { ConnectionWithFullSeekerProfile } from "../../data/connection";
import { classNames } from "../../utils/classNameList";
import { AcceptConnectionError } from "../../utils/errors";
import { formatMoney } from "../../utils/format";
import EducationCard from "../common/EducationCard";
import ExperienceCard from "../common/ExperienceCard";
import VideoPlayer from "../common/VideoPlayer";
import CardDeclinedModal from "../payments/CardDeclinedModal";
import NewPaymentPackageModal from "../payments/NewPaymentPackageModal";
import "./ConnectionPage.scss";
import ConversationPanel from "./ConversationPanel";
import { SearchParams } from "./routes";

type ConnectionPageProps = ConnectionPageConnectedProps & {
  connectionId: number;
  showExpander?: boolean;
  expandClick?: (expanded: boolean) => void;
  className?: string;
  expanded?: boolean;
  style?: CSSProperties;
};

const ConnectionPage = ({
  connectionId,
  fetchConnection,
  locations,
  getLocationById,
  updateConnection,
  acceptConnection,
  declineConnection,
  showExpander = true,
  expandClick,
  className,
  expanded = false,
  style = {},
}: ConnectionPageProps) => {
  const [loading, setLoading] = useState(true);
  const [waiting, setWaiting] = useState(false);
  const [connection, setConnection] = useState<ConnectionWithFullSeekerProfile | null>(null);
  const [interestLevel, setInterestLevel] = useState(0);
  const [connectionFeeConfirmed, setConnectionFeeConfirmed] = useState(false);
  const [declineConfirmed, setDeclineConfirmed] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showCreatePaymentPackageModal, setShowCreatePaymentPackageModal] = useState(false);
  const [showCardDeclinedModal, setShowCardDeclinedModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const initializeData = useCallback(() => {
    setLoading(true);
    fetchConnection(connectionId)
      .then((_connection) => {
        setConnection(_connection);
        setInterestLevel(_connection.interest_level);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [connectionId, fetchConnection]);

  const savePendingChanges = useCallback(() => {
    if (connection && (!connection.reviewed || connection.interest_level !== interestLevel)) {
      setWaiting(true);
      return updateConnection(connection.id, true, interestLevel)
        .then((updatedConnection) => {
          // might have changed connection ids while the REST call was being completed
          if (updatedConnection.id === connectionId) {
            setConnection(updatedConnection);
            setInterestLevel(updatedConnection.interest_level);
          }
          return true;
        })
        .catch((error) => {
          console.error(error);
          AppToast.show({
            type: "error",
            text: `Failed to save changes to connection ${connection.seeker.profile.first_name} ${connection.seeker.profile.last_name}`,
          });
        })
        .finally(() => setWaiting(false));
    } else {
      return Promise.resolve(true);
    }
  }, [updateConnection, connectionId, connection, setConnection, interestLevel, setInterestLevel]);

  const acceptCurrentConnection = useCallback(() => {
    setWaiting(true);
    acceptConnection(connectionId)
      .then((updatedConnection) => {
        if (updatedConnection.id === connectionId) {
          setConnection(updatedConnection);
          setInterestLevel(updatedConnection.interest_level);
        }
      })
      .catch((error) => {
        if (error instanceof AcceptConnectionError) {
          switch (error.reason) {
            case AcceptConnectionError.ReasonCodes.AlreadyApproved: {
              initializeData(); // refresh connection
              break;
            }
            case AcceptConnectionError.ReasonCodes.PaymentDeclined: {
              setShowCardDeclinedModal(true);
              break;
            }
            case AcceptConnectionError.ReasonCodes.PaymentMethodMissing: {
              setShowCreatePaymentPackageModal(true);
              break;
            }
            default:
          }
        } else {
          AppToast.show({ text: error.message, type: "error" });
        }
      })
      .finally(() => setWaiting(false));
  }, [connectionId, acceptConnection, initializeData, setShowCardDeclinedModal]);

  const declineCurrentConnection = useCallback(() => {
    setWaiting(true);
    declineConnection(connectionId)
      .then((updatedConnection) => {
        if (updatedConnection.id === connectionId) {
          const navLocationSearch = new URLSearchParams(location.search);
          navLocationSearch.delete(SearchParams.SelectedConnectionId);
          navigate({ pathname: location.pathname, search: navLocationSearch.toString() });
        }
      })
      .catch(() => {})
      .finally(() => setWaiting(false));
  }, [connectionId, declineConnection, navigate, location]);

  const acceptButton = useMemo(() => {
    if (connection == null) return null;
    const icon = <FontAwesomeIcon className="me-2" icon={faCircleCheck} />;
    return connection.accept_fee_cents == null ? (
      <Button variant="success" onClick={acceptCurrentConnection}>
        {icon}
        Connect
      </Button>
    ) : connectionFeeConfirmed || connection.accept_fee_cents === 0 ? (
      <Button variant="success" onClick={acceptCurrentConnection}>
        {icon}
        {connectionFeeConfirmed ? "Confirm" : "Connect"}
      </Button>
    ) : (
      <Button variant="success" onClick={() => setConnectionFeeConfirmed(true)}>
        {icon}
        {`Connect (${formatMoney(connection.accept_fee_cents / 100)})`}
      </Button>
    );
  }, [connection, acceptCurrentConnection, connectionFeeConfirmed, setConnectionFeeConfirmed]);

  useEffect(() => {
    setConnectionFeeConfirmed(false);
    setDeclineConfirmed(false);
    savePendingChanges();
  }, [location, savePendingChanges]);

  useEffect(() => {
    initializeData();
  }, [initializeData]);

  const expandClickHandler = useCallback(() => {
    if (expandClick) {
      expandClick(!expanded);
    }
  }, [expanded, expandClick]);

  const connectionLocation = connection && getLocationById(connection.location_id);
  const appliedPositionNames =
    connection && connectionLocation
      ? connectionLocation.jobs.filter((j) => connection.job_ids.includes(j.id)).map((j) => j.title)
      : [];

  return (
    <div className={classNames("h-100 bg-white border rounded p-3", className)} style={{ ...style, overflowY: "auto" }}>
      <div className="d-flex flex-column gap-3 position-relative h-100">
        {loading || connection == null ? (
          <LoadingOverlay />
        ) : (
          <React.Fragment>
            {showExpander && (
              <IconButton
                icon={expanded ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
                onClick={expandClickHandler}
                style={{ position: "absolute", right: "0", top: "0" }}
              />
            )}
            <div className="seeker-header flex-grow-0 flex-shrink-0">
              <Avatar user={connection.seeker} onPlayVideo={() => setShowVideo(true)} />
              <div className="details d-flex flex-column gap-3">
                <div>
                  <div className="d-flex flex-row align-items-center gap-2">
                    <div className="fs-4 fw-semibold">
                      {connection.seeker.profile.first_name} {connection.seeker.profile.last_name}
                    </div>
                    <a
                      href="#interest"
                      onClick={(event) => {
                        event.preventDefault();
                        setInterestLevel(interestLevel === 0 ? 1 : 0);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={interestLevel > 0 ? faSolidStar : faRegularStar}
                        className={interestLevel > 0 ? "favorite-icon-selected" : "favorite-icon"}
                      />
                    </a>
                    <Badge
                      pill={true}
                      bg={
                        connection.status === ConnectionStatus.Pending
                          ? "warning"
                          : connection.status === ConnectionStatus.Accepted
                          ? "success"
                          : "danger"
                      }
                    >
                      {connectionStatusLabel(connection.status)}
                    </Badge>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faClock} className="fixed-width-icon me-2" />
                    {`Submitted: ${formatDistanceToNowStrict(parseISO(connection.last_submitted_at))} ago`}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faList} className="fixed-width-icon me-2" />
                    <span>Position(s): </span>
                    {appliedPositionNames.map((name, index) => (
                      <Badge key={`pos-${index}`} pill={true} className="bg-white text-dark border border-dark me-2">
                        {name}
                      </Badge>
                    ))}
                  </div>
                  {locations.length > 1 && connectionLocation && (
                    <div>
                      <FontAwesomeIcon icon={faLocationDot} className="fixed-width-icon me-2" />
                      {`Location: ${connectionLocation.name} (${connectionLocation.address_1})`}
                    </div>
                  )}
                </div>
                <div className="actions">
                  {[ConnectionStatus.Pending, ConnectionStatus.Declined].includes(connection.status) && acceptButton}
                  {connection.status !== ConnectionStatus.Declined && (
                    <Button
                      variant="danger"
                      style={{ flex: "0 0 auto" }}
                      onClick={() => {
                        if (declineConfirmed) {
                          declineCurrentConnection();
                        } else {
                          setDeclineConfirmed(true);
                        }
                      }}
                    >
                      <FontAwesomeIcon className="me-2" icon={faTrashCan} />
                      {declineConfirmed ? "Confirm" : "Decline"}
                    </Button>
                  )}
                  {connection.status === ConnectionStatus.Declined && (
                    <div className="text-muted fs-smaller" style={{ flex: "1 1" }}>
                      This seeker connection has been declined, but can be re-established by clicking Connect.
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex flex-row gap-3 pb-3">
              <div className="d-flex flex-column gap-3" style={{ flex: "1 1 50%" }}>
                <ExperienceCard experiences={connection.seeker.profile.experiences} />
                <EducationCard
                  educations={connection.seeker.profile.educations}
                  certificates={connection.seeker.profile.certificates}
                />
              </div>
              <ConversationPanel connection={connection} style={{ flex: "1 1 50%" }} />
            </div>
            {showVideo &&
              connection.seeker.profile.optimized_video_url &&
              connection.seeker.profile.optimized_video_thumb_url && (
                <VideoPlayer
                  src={connection.seeker.profile.optimized_video_url}
                  poster={connection.seeker.profile.optimized_video_thumb_url}
                  autoPlay={true}
                  onClose={() => setShowVideo(false)}
                />
              )}
          </React.Fragment>
        )}
        {waiting && <LoadingOverlay />}
        {showCreatePaymentPackageModal && (
          <NewPaymentPackageModal
            visible={true}
            locationId={connection!.location_id}
            onClosed={() => setShowCreatePaymentPackageModal(false)}
          />
        )}
        {showCardDeclinedModal && <CardDeclinedModal visible={true} onClosed={() => setShowCardDeclinedModal(false)} />}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    locations: state.locations.locations!,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchConnection: (connectionId: number) => dispatch(getConnectionById(connectionId)),
    getLocationById: (locationId: number) => dispatch(getLocationById(locationId)),
    updateConnection: (connectionId: number, reviewed: boolean, interestLevel: number) =>
      dispatch(updateConnectionById(connectionId, reviewed, interestLevel)),
    acceptConnection: (connectionId: number) => dispatch(acceptConnection(connectionId)),
    declineConnection: (connectionId: number) => dispatch(declineConnection(connectionId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ConnectionPageConnectedProps = ConnectedProps<typeof connector>;

export default connector(ConnectionPage);
